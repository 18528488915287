export const convertRGBA = {
  methods:{
    convertRGBA: function(colorArray){

      var color = "rgba("

      for (var i = 0; i < colorArray.length; i++) {
        color += colorArray[i]

        if(i != colorArray.length - 1){

          color += ','

        }
      }

      color += ")"

      return color
    }
  }
}
