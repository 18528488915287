var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('div',{staticClass:"event-standings"},[(_vm.loadImage)?_c('PublicEventStandingsImageGenerator',{attrs:{"dev-mode":false,"division-to-render":_vm.divisionToRender,"image-render-mode":_vm.imageRenderMode,"player-id":_vm.usersPlayerId},on:{"completed":function($event){_vm.loadImage = false}}}):_vm._e(),(_vm.eventData.leaderboard.length == 0)?_c('div',{staticClass:"no-rounds-finihed"},[_c('h4',[_vm._v("No rounds played yet")]),_c('p',[_vm._v("Once a round is finished the results will automagically be updated.")])]):_c('div',_vm._l((_vm.eventData.leaderboard),function(division,index){return _c('div',{key:division.type,staticClass:"division-results",attrs:{"id":'division' + index}},[_c('div',{staticClass:"header",style:({ zIndex: 500 - index })},[_c('h4',[_vm._v(" "+_vm._s(division.name)+" "),_c('span',[_vm._v("("+_vm._s(division.players.length)+")")])]),_c('TjingContextMenu',{attrs:{"position":"bottom-end","loading":_vm.loadImage,"title":"Download and share image","icon":"download","menuItems":[
            {
              title: 'Your result',
              disabled: !_vm.playerInDivision(division),
              action: 'YOU',
            },
            { title: 'Top 10 results', disabled: false, action: 'TOP' },
            { title: 'All results', disabled: false, action: 'ALL' } ]},on:{"ALL":function($event){return _vm.generateImage(division, 'ALL')},"TOP":function($event){return _vm.generateImage(division, 'TOP')},"YOU":function($event){return _vm.generateImage(division, 'YOU')}}})],1),_c('table',{staticClass:"leaderboard-table"},[_c('thead',{staticClass:"text-uppercase"},[_c('tr',[_c('th',{staticClass:"position"},[_vm._v("Pos")]),_c('th',{staticClass:"player"},[_vm._v("Player")]),_c('th',{staticClass:"pdga d-none d-lg-table-cell"},[_vm._v("pdga")]),_c('th',{staticClass:"rating d-none d-sm-table-cell"}),_vm._l((_vm.eventData.rounds),function(round,index){return _c('th',{key:index,staticClass:"results landscape"},[_vm._v(" R"+_vm._s(index + 1)+" ")])}),_c('th',{staticClass:"par"},[_vm._v("Par")]),_c('th',{staticClass:"total"}),(_vm.eventData.tour.scoringType == 'POINTS')?_c('th',{staticClass:"points"}):_vm._e()],2)]),_c('tbody',_vm._l((division.players),function(player){return _c('tr',{key:player.userId},[_c('td',{staticClass:"position"},[_vm._v(_vm._s(player.place))]),_c('td',{staticClass:"player"},[_vm._v(" "+_vm._s(player.firstName)+" "+_vm._s(player.lastName)+" ")]),_c('td',{staticClass:"pdga d-none d-lg-table-cell"},[_c('a',{attrs:{"href":'https://www.pdga.com/player/' + player.pdgaNumber,"target":"_blank"}},[_vm._v(_vm._s(player.pdgaNumber))])]),_c('td',{staticClass:"rating d-none d-sm-table-cell"},[_c('a',{attrs:{"href":'https://www.pdga.com/player/' +
                  player.pdgaNumber +
                  '/details',"target":"_blank"}},[_vm._v(_vm._s(player.pdgaRating))])]),_vm._l((player.poolLeaderboards),function(result,resultIndex){return _c('td',{key:player.playerId + resultIndex + 'result',staticClass:"result landscape"},[_vm._v(" "+_vm._s(result.score)+" ")])}),_vm._l((_vm.eventData.rounds.length -
              player.poolLeaderboards.length),function(round){return _c('td',{key:player.playerId + round + 'round',staticClass:"result landscape"})}),_c('td',{staticClass:"par"},[_vm._v(_vm._s(_vm.convertPar(player.par)))]),(player.isDnf == false && player.isDns == false)?_c('td',{staticClass:"total"},[_vm._v(" "+_vm._s(player.score)+" ")]):(player.isDnf)?_c('td',{staticClass:"dnf"},[_vm._v("DNF")]):(player.isDns)?_c('td',{staticClass:"dnf"},[_vm._v("DNS")]):_vm._e(),(_vm.eventData.tour.scoringType == 'POINTS')?_c('td',{staticClass:"points"},[_vm._v(" "+_vm._s(player.points)+" ")]):_vm._e()],2)}),0)])])}),0)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }