var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('div',{staticClass:"event-standings-image-generator",class:{ 'dev-mode': _vm.devMode }},[_c('div',{staticClass:"render-container",class:_vm.imageRenderMode == 'ALL' ? 'all' : ''},[_c('div',{staticClass:"target",attrs:{"id":'source' + _vm.divisionToRender.id}},[_c('div',{staticClass:"image-header"},[_c('div',{staticClass:"left"},[_c('p',{staticClass:"event-name"},[_vm._v(" "+_vm._s(_vm.eventData.name)+" ")]),_c('p',{staticClass:"division"},[_vm._v(_vm._s(_vm.divisionToRender.name))])]),_c('div',{staticClass:"right"},[(
              _vm.imageRenderMode == 'TOP' && _vm.divisionToRender.players.length > 11
            )?_c('div',{staticClass:"fold"},[_c('p',{staticClass:"top-10"},[_vm._v("Top 10")])]):_vm._e()])]),(!_vm.imagesRendered)?_c('div',{staticClass:"division-results",class:{
          'multi-round':
            _vm.eventData.rounds.length > 1 && _vm.eventData.rounds.length < 5,
        }},[_c('table',{staticClass:"leaderboard-table"},[_c('thead',{staticClass:"text-uppercase"},[_c('tr',[_c('th',{staticClass:"spacer"}),_c('th',{staticClass:"position"},[_vm._v("Pos")]),_c('th',{staticClass:"player"},[_vm._v("Player")]),_c('th',{staticClass:"pdga d-none"},[_vm._v("pdga")]),_c('th',{staticClass:"rating d-none"}),_vm._l((_vm.eventData.rounds),function(round,index){return (
                  _vm.eventData.rounds.length < 4 && _vm.eventData.rounds.length > 1
                )?_c('th',{key:index,staticClass:"results landscape"},[_vm._v(" R"+_vm._s(index + 1)+" ")]):_vm._e()}),_c('th',{staticClass:"par"},[_vm._v("Par")]),_c('th',{staticClass:"total"},[_vm._v("TOT")]),_c('th',{staticClass:"spacer"})],2)]),_c('tbody',_vm._l((_vm.shortLeaderboard),function(player,index){return _c('tr',{key:player.userId,class:{
                marked:
                  _vm.playerId == player.playerId && _vm.imageRenderMode == 'YOU',
                'before-marked':
                  _vm.playerBeforeMarked == index && _vm.imageRenderMode == 'YOU',
              }},[_c('td',{staticClass:"spacer"}),_c('td',{staticClass:"position"},[_vm._v(_vm._s(player.place))]),_c('td',{staticClass:"player"},[_vm._v(" "+_vm._s(player.firstName)+" "+_vm._s(player.lastName)+" ")]),_c('td',{staticClass:"pdga d-none"},[_c('a',{attrs:{"href":'https://www.pdga.com/player/' + player.pdgaNumber,"target":"_blank"}},[_vm._v(_vm._s(player.pdgaNumber))])]),_c('td',{staticClass:"rating d-none"},[_c('a',{attrs:{"href":'https://www.pdga.com/player/' +
                    player.pdgaNumber +
                    '/details',"target":"_blank"}},[_vm._v(_vm._s(player.pdgaRating))])]),_vm._l((player.poolLeaderboards),function(result,resultIndex){return (
                  player.poolLeaderboards.length < 4 &&
                  player.poolLeaderboards.length > 1
                )?_c('td',{key:player.playerId + resultIndex + 'result',staticClass:"result landscape"},[_vm._v(" "+_vm._s(result.score)+" ")]):_vm._e()}),_vm._l((_vm.eventData.rounds.length -
                player.poolLeaderboards.length),function(n){return (
                  player.poolLeaderboards.length < 4 &&
                  player.poolLeaderboards.length > 1
                )?_c('td',{key:player.playerId + n + 'resultblank',staticClass:"result landscape"}):_vm._e()}),_c('td',{staticClass:"par"},[_vm._v(_vm._s(_vm.convertPar(player.par)))]),(player.isDnf == false && player.isDns == false)?_c('td',{staticClass:"total"},[_vm._v(" "+_vm._s(player.score)+" ")]):(player.isDnf)?_c('td',{staticClass:"dnf"},[_vm._v("DNF")]):(player.isDns)?_c('td',{staticClass:"dnf"},[_vm._v("DNS")]):_vm._e(),_c('td',{staticClass:"spacer"})],2)}),0)])]):_vm._e(),_c('div',{staticClass:"division-footer"},[_c('div',{staticClass:"footer-left"},[_c('p',[_vm._v(_vm._s(_vm.eventDate))])]),_c('div',{staticClass:"footer-right"},[_c('p',[_vm._v("Results from")]),_c('Logo')],1)])])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }