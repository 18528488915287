<template>
  <div
    class="event-standings-image-generator"
    :class="{ 'dev-mode': devMode }"
    v-if="!loading"
  >
    <div
      class="render-container"
      :class="imageRenderMode == 'ALL' ? 'all' : ''"
    >
      <div :id="'source' + divisionToRender.id" class="target">
        <div class="image-header">
          <div class="left">
            <p class="event-name">
              {{ eventData.name }}
            </p>
            <p class="division">{{ divisionToRender.name }}</p>
          </div>
          <div class="right">
            <div
              class="fold"
              v-if="
                imageRenderMode == 'TOP' && divisionToRender.players.length > 11
              "
            >
              <p class="top-10">Top 10</p>
            </div>
          </div>
        </div>
        <div
          class="division-results"
          v-if="!imagesRendered"
          :class="{
            'multi-round':
              eventData.rounds.length > 1 && eventData.rounds.length < 5,
          }"
        >
          <table class="leaderboard-table">
            <thead class="text-uppercase">
              <tr>
                <th class="spacer"></th>
                <th class="position">Pos</th>
                <th class="player">Player</th>
                <th class="pdga d-none">pdga</th>
                <th class="rating d-none"></th>
                <th
                  class="results landscape"
                  v-for="(round, index) in eventData.rounds"
                  :key="index"
                  v-if="
                    eventData.rounds.length < 4 && eventData.rounds.length > 1
                  "
                >
                  R{{ index + 1 }}
                </th>
                <th class="par">Par</th>
                <th class="total">TOT</th>
                <th class="spacer"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(player, index) in shortLeaderboard"
                :key="player.userId"
                :class="{
                  marked:
                    playerId == player.playerId && imageRenderMode == 'YOU',
                  'before-marked':
                    playerBeforeMarked == index && imageRenderMode == 'YOU',
                }"
              >
                <td class="spacer"></td>
                <td class="position">{{ player.place }}</td>
                <td class="player">
                  {{ player.firstName }} {{ player.lastName }}
                </td>
                <td class="pdga d-none">
                  <a
                    :href="'https://www.pdga.com/player/' + player.pdgaNumber"
                    target="_blank"
                    >{{ player.pdgaNumber }}</a
                  >
                </td>
                <td class="rating d-none">
                  <a
                    :href="
                      'https://www.pdga.com/player/' +
                      player.pdgaNumber +
                      '/details'
                    "
                    target="_blank"
                    >{{ player.pdgaRating }}</a
                  >
                </td>
                <td
                  class="result landscape"
                  v-for="(result, resultIndex) in player.poolLeaderboards"
                  :key="player.playerId + resultIndex + 'result'"
                  v-if="
                    player.poolLeaderboards.length < 4 &&
                    player.poolLeaderboards.length > 1
                  "
                >
                  {{ result.score }}
                </td>
                <td
                  class="result landscape"
                  v-for="n in eventData.rounds.length -
                  player.poolLeaderboards.length"
                  :key="player.playerId + n + 'resultblank'"
                  v-if="
                    player.poolLeaderboards.length < 4 &&
                    player.poolLeaderboards.length > 1
                  "
                ></td>
                <td class="par">{{ convertPar(player.par) }}</td>
                <td
                  class="total"
                  v-if="player.isDnf == false && player.isDns == false"
                >
                  {{ player.score }}
                </td>
                <td class="dnf" v-else-if="player.isDnf">DNF</td>
                <td class="dnf" v-else-if="player.isDns">DNS</td>
                <td class="spacer"></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="division-footer">
          <div class="footer-left">
            <p>{{ eventDate }}</p>
          </div>
          <div class="footer-right">
            <p>Results from</p>
            <Logo />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { convertRGBA } from "@/mixins/ConvertRGBA";
import domtoimage from "dom-to-image";
import Logo from "@/components/Logo";

export default {
  name: "PublicEventStandingsImageGenerator",
  props: ["division-to-render", "image-render-mode", "player-id", "dev-mode"],
  components: { Logo },
  mixins: [convertRGBA],
  data() {
    return {
      loading: true,
      eventData: null,
      sortBy: "",
      ascending: true,
      imagesRendered: false,
    };
  },
  computed: {
    eventDate() {
      const startDate = this.eventData.startDate;
      const endDate = this.eventData.endDate;

      if (moment(startDate).isSame(endDate, "day")) {
        return moment(startDate).format("D MMMM YYYY");
      }
      if (moment(startDate).isSame(endDate, "month")) {
        return `${moment(startDate).format("D")}–${moment(endDate).format(
          "D MMMM YYYY"
        )}`;
      }

      return `${moment(startDate).format("D MMM")}–${moment(startDate).format(
        "D MMM"
      )}, ${moment(startDate).format("YYYY")}`;
    },
    shortLeaderboard() {
      let arrayStart = 0;
      let arrayEnd = 10;
      const arrayLength = this.leaderboard.players.length;

      if (this.imageRenderMode == "YOU") {
        const userId = this.userId;
        let arrayStartIndex = 0;

        this.leaderboard.players.forEach((player, index) => {
          if (player.playerId == this.playerId) arrayStartIndex = index;
        });

        if (arrayStartIndex > arrayLength - 8)
          arrayStartIndex = arrayLength - 8;
        if (arrayStartIndex < 10) arrayStartIndex = 2;

        arrayStart = arrayStartIndex - 2;
        arrayEnd = arrayStartIndex + 8;
      } else if (this.imageRenderMode == "ALL") {
        arrayStart = 0;
        arrayEnd = arrayLength;
      }

      return this.leaderboard.players.slice(arrayStart, arrayEnd);
    },
    playerBeforeMarked() {
      let returnIndex = null;

      this.shortLeaderboard.forEach((player, index) => {
        if (this.playerId == player.playerId) {
          returnIndex = index - 1;
        }
      });

      return returnIndex;
    },
    leaderboard() {
      return this.eventData.leaderboard.find(
        (leaderboard) => leaderboard.id == this.divisionToRender.id
      );
    },
    tourDivisions() {
      return this.$store.getters.tourDivisions;
    },
  },
  watch: {
    "$route.params.eventId"() {
      this.loadData();
    },
    tourDivisions: function () {
      if (this.tourDivisions.length > 0) {
        this.loadData();
      }
    },
  },
  methods: {
    formattedGraphData(type) {
      let percentage = Math.round(this.eventData.stats[type].part * 100);
      let count = this.eventData.stats[type].count;
      let stat = {
        count: count,
        part: [percentage, 100 - percentage],
      };

      return stat;
    },
    convertPar(par) {
      if (par == 0) {
        return "E";
      } else if (par > 0) {
        return "+" + par;
      } else {
        return par;
      }
    },
    async getImage(id) {
      let node = document.getElementById("source" + id);
      try {
        let result = await domtoimage.toPng(node, { bgcolor: "#fff" });
        let img = new Image();
        img.src = result;

        let filename = "";

        if (this.imageRenderMode == "TOP")
          filename = `${this.eventData.name} - ${this.divisionToRender.name} - Top 10.png`;
        if (this.imageRenderMode == "ALL")
          filename = `${this.eventData.name} - ${this.divisionToRender.name} - All.png`;
        if (this.imageRenderMode == "YOU")
          filename = `${this.eventData.name} - ${this.divisionToRender.name} - You.png`;

        let saveImg = document.createElement("a"); // New link we use to save it with
        saveImg.href = img.src; // Assign image src to our link target
        saveImg.download = filename; // set filename for download
        saveImg.click();

        this.$emit("completed");
      } catch (err) {
        console.error("oops, something went wrong!", err);
      }
    },
    async loadData() {
      this.$axios({
        data: {
          query: `
          {
            event(eventId:"${this.$router.currentRoute.params.eventId}"){
              id
              name
              startDate
              endDate
              players{
                id
                userId
                user{
                  firstName
                  lastName
                }
              }
              leaderboard{
                id
                name
                type
                players{
                  playerId
            			place
                  score
            			par
                  points
                	isDnf
                  isDns
                  playerId
                  firstName
                  lastName
                  pdgaNumber
                  pdgaRating
                  poolLeaderboards{
                    place
                    score
                    par
                    points
                  }
                }
              }
              tour{
                name
                scoringType
              }
              rounds{
                pools{
                  id
                }
              }
            }
          }
          `,
        },
      }).then((result) => {
        this.eventData = result.data.data.event;

        this.loading = false;

        if (this.devMode == false) {
          this.$nextTick(async () => {
            await this.getImage(this.divisionToRender.id);
            this.imagesRendered = true;
          });
        }
      });
    },
  },
  beforeMount() {
    this.loadData();
  },
};
</script>

<style scoped lang="scss">
.event-standings-image-generator {
  position: relative;
  overflow: hidden;

  &.dev-mode {
    overflow: unset;

    .render-container {
      position: relative;
      top: unset;
    }
  }

  .render-container {
    position: absolute;
    width: 1080px;
    min-height: 1080px;
    height: 1080px;
    top: -1500px;

    &.dev-mode {
      position: relative;
      top: unset;
    }

    .target {
      height: 1080px;
    }

    &.all {
      height: auto;
      min-height: 1080px;

      .target {
        height: 100%;
        position: relative;
        min-height: 1080px;
      }

      .division-results {
        padding: 0 0 132px 0;
      }
    }
  }
}

.image-header {
  background: $snow;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $sleet;
  margin-bottom: 12px;

  .left {
    padding: 40px 50px 40px 50px;
    width: calc(100% - 260px);
    .event-name {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 48px;
      @include Gilroy-Bold;
      margin: 0;
    }
    .division {
      margin: 0;
      font-size: 40px;
      @include Gilroy-Bold;
    }
  }
  .right {
    padding-right: 45px;

    .fold {
      height: 170px;
      width: 215px;
      background: $twilight;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      border-bottom-right-radius: 24px;
      border-bottom-left-radius: 24px;
      padding-bottom: 22px;

      p {
        @include Gilroy-Bold;
        white-space: nowrap;
        color: white;
        font-size: 48px;
        margin: 0;
      }
    }
  }
}
.no-rounds-finihed {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 50px;
  h4 {
    font-size: 20px;
    @include Gilroy-Bold;
    margin: 0 0 8px 0;
  }

  p {
    font-size: 16px;
    text-align: center;
    color: $strom;
    padding: 0 10%;
  }
}

.stat-charts {
  display: flex;
  justify-content: space-between;
  margin-bottom: 34px;
}

.division-results {
  padding: 0 0 48px 0;
  position: relative;

  .header {
    background-color: $mist;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    height: 48px;
    align-items: center;
    padding: 0 10px;
    position: relative !important;
    top: 0;
    z-index: 1;
    h4 {
      margin: 0;
      font-size: 24px;
      text-align: left;

      span {
        @include Gilroy-Regular;
      }
    }
  }
}
.stat-container {
  width: 33%;
  max-width: 90px;

  p {
    margin-top: 10px;
    margin-bottom: 0px;
    text-align: center;
    font-size: 12px;
    color: $wolf;
    @include Gilroy-Bold;
  }
}

.multi-round {
  table {
    thead {
      th {
        &.total {
          width: 6%;
        }
        &.par {
          width: 10%;
        }
        &.results {
          width: 8%;
        }
      }
    }
    tbody {
      td {
        &.player {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        &.result {
          @include Gilroy-Regular;
        }
      }
    }
  }
}

table {
  width: 100%;
  table-layout: fixed;

  thead {
    th {
      font-size: 24px;
      @include Gilroy-Bold;
      color: $midnight;
    }
  }

  th {
    top: 30px;
    z-index: 1;

    &.spacer {
      width: 44px;
      border-bottom: none;
    }

    &.position {
      width: 6%;
      text-align: center;
    }
    &.player {
      padding-left: 30px;
      width: auto;
    }
    &.results {
      width: 10%;
      text-align: center;
    }
    &.par {
      width: 18%;
      text-align: center;
    }
    &.points {
      width: 15%;
      text-align: center;
      &:after {
        content: "pts";
      }
    }
    &.total {
      width: 6%;
      text-align: center;
    }
  }

  tbody {
    td {
      height: 42px;
    }
  }
  tr:last-of-type {
    td {
      border-bottom: none !important;
    }
  }

  .before-marked {
    td {
      border-bottom: none !important;
    }
  }

  .marked {
    td {
      background: #cdf0f2;
      border-bottom: none !important;
    }
  }

  td {
    height: 65px !important;
    font-size: 28px !important;
    border-bottom: 1px solid $sleet !important;

    &.dnf {
      text-align: center;
      @include Gilroy-Bold;
    }
    &.dns {
      text-align: center;
      @include Gilroy-Bold;
    }
    &.position {
      text-align: center;
      @include Gilroy-Bold;
    }
    &.total {
      text-align: center;
      @include Gilroy-Bold;
    }
    &.result {
      @include Gilroy-Bold;
      text-align: center;
    }
    &.player {
      @include Gilroy-Bold;
      padding-left: 30px;
    }
    &.points {
      text-align: center;
      @include Gilroy-Regular;
    }
    &.par {
      @include Gilroy-Bold;
      text-align: center;
    }
    &:last-child {
      @include Gilroy-Bold;
    }
  }
}

h4 {
  margin-bottom: 15px;
}

.division-footer {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $snow;
  padding: 26px 30px;
  border-top: 1px solid $sleet;

  .footer-right {
    display: flex;
    align-items: center;
    p {
      font-size: 32px;
      margin: 0;
    }
    svg {
      height: 56px;
      width: auto;
      margin-left: 20px;
    }
  }

  .footer-left {
    p {
      font-size: 32px;
      margin: 0;
      margin-left: 1.5rem;
    }
  }

  p {
    margin: 0;
  }
}

@media (max-width: 813px) and (orientation: landscape) {
  .header {
    position: relative;
  }
  table {
    thead {
      th {
        top: 0;
      }
    }
  }
}
</style>
