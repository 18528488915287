<template>
  <div class="event-standings" v-if="!loading">
    <!--
      In order to view the content that will render to the image simpply change dev-mode in the component to have it render to the screen instead of creating an image.
    -->
    <PublicEventStandingsImageGenerator
      :dev-mode="false"
      :division-to-render="divisionToRender"
      :image-render-mode="imageRenderMode"
      :player-id="usersPlayerId"
      @completed="loadImage = false"
      v-if="loadImage"
    />
    <div class="no-rounds-finihed" v-if="eventData.leaderboard.length == 0">
      <h4>No rounds played yet</h4>
      <p>Once a round is finished the results will automagically be updated.</p>
    </div>
    <div v-else>
      <div
        class="division-results"
        :id="'division' + index"
        v-for="(division, index) in eventData.leaderboard"
        :key="division.type"
      >
        <div class="header" :style="{ zIndex: 500 - index }">
          <h4>
            {{ division.name }} <span>({{ division.players.length }})</span>
          </h4>
          <TjingContextMenu
            position="bottom-end"
            :loading="loadImage"
            title="Download and share image"
            icon="download"
            :menuItems="[
              {
                title: 'Your result',
                disabled: !playerInDivision(division),
                action: 'YOU',
              },
              { title: 'Top 10 results', disabled: false, action: 'TOP' },
              { title: 'All results', disabled: false, action: 'ALL' },
            ]"
            @ALL="generateImage(division, 'ALL')"
            @TOP="generateImage(division, 'TOP')"
            @YOU="generateImage(division, 'YOU')"
          />
        </div>
        <table class="leaderboard-table">
          <thead class="text-uppercase">
            <tr>
              <th class="position">Pos</th>
              <th class="player">Player</th>
              <th class="pdga d-none d-lg-table-cell">pdga</th>
              <th class="rating d-none d-sm-table-cell"></th>
              <th
                class="results landscape"
                v-for="(round, index) in eventData.rounds"
                :key="index"
              >
                R{{ index + 1 }}
              </th>
              <th class="par">Par</th>
              <th class="total"></th>
              <th
                class="points"
                v-if="eventData.tour.scoringType == 'POINTS'"
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="player in division.players" :key="player.userId">
              <td class="position">{{ player.place }}</td>
              <td class="player">
                {{ player.firstName }} {{ player.lastName }}
              </td>
              <td class="pdga d-none d-lg-table-cell">
                <a
                  :href="'https://www.pdga.com/player/' + player.pdgaNumber"
                  target="_blank"
                  >{{ player.pdgaNumber }}</a
                >
              </td>
              <td class="rating d-none d-sm-table-cell">
                <a
                  :href="
                    'https://www.pdga.com/player/' +
                    player.pdgaNumber +
                    '/details'
                  "
                  target="_blank"
                  >{{ player.pdgaRating }}</a
                >
              </td>
              <td
                class="result landscape"
                v-for="(result, resultIndex) in player.poolLeaderboards"
                :key="player.playerId + resultIndex + 'result'"
              >
                {{ result.score }}
              </td>
              <td
                class="result landscape"
                v-for="round in eventData.rounds.length -
                player.poolLeaderboards.length"
                :key="player.playerId + round + 'round'"
              ></td>
              <td class="par">{{ convertPar(player.par) }}</td>
              <td
                class="total"
                v-if="player.isDnf == false && player.isDns == false"
              >
                {{ player.score }}
              </td>
              <td class="dnf" v-else-if="player.isDnf">DNF</td>
              <td class="dnf" v-else-if="player.isDns">DNS</td>
              <td class="points" v-if="eventData.tour.scoringType == 'POINTS'">
                {{ player.points }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { convertRGBA } from "@/mixins/ConvertRGBA";
import PublicEventStandingsImageGenerator from "@/components/PublicEventStandingsImageGenerator";
import TjingContextMenu from "@/components/TjingContextMenu";

export default {
  name: "PublicEventStandings",
  components: {
    PublicEventStandingsImageGenerator,
    TjingContextMenu,
  },
  mixins: [convertRGBA],
  data() {
    return {
      loading: true,
      eventData: null,
      sortBy: "",
      ascending: true,
      loadImage: false,
      divisionToRender: null,
      imageRenderMode: "USER",
    };
  },
  computed: {
    usersPlayerId() {
      const userId = this.userInfo.sessionInfo.userId;
      //const userId = "4c4f7693-66fd-4dab-a8f9-ad0e9e1b1c9b";

      let playerId = null;

      const player = this.eventData.players.find(
        (player) => player.user.id == userId
      );

      if (player) {
        playerId = player.id;
      }

      return playerId;
    },
    userInfo() {
      return this.$store.getters.userInfo;
    },
    tourDivisions() {
      return this.$store.getters.tourDivisions;
    },
  },
  watch: {
    "$route.params.eventId"() {
      this.loadData();
    },
    tourDivisions: function () {
      if (this.tourDivisions.length > 0) {
        this.loadData();
      }
    },
  },
  methods: {
    playerInDivision(division) {
      return division.players.some(
        (player) => player.playerId == this.usersPlayerId
      );
    },
    generateImage(division, mode) {
      this.divisionToRender = division;
      this.imageRenderMode = mode;
      this.loadImage = true;
    },
    formattedGraphData(type) {
      let percentage = Math.round(this.eventData.stats[type].part * 100);
      let count = this.eventData.stats[type].count;
      let stat = {
        count: count,
        part: [percentage, 100 - percentage],
      };

      return stat;
    },
    convertPar(par) {
      if (par == 0) {
        return "E";
      } else if (par > 0) {
        return "+" + par;
      } else {
        return par;
      }
    },

    loadData() {
      this.$axios({
        data: {
          query: `
          {
            event(eventId:"${this.$router.currentRoute.params.eventId}"){
              id
              players{
                id
                user{
                  id
                }
              }
              leaderboard{
                id
                name
                type
                players{
                  playerId
            			place
                  score
            			par
                  points
                	isDnf
                  isDns
                  playerId
                  firstName
                  lastName
                  pdgaNumber
                  pdgaRating
                  poolLeaderboards{
                    poolId
                    place
                    score
                    par
                    points
                  }
                }
              }
              tour{
                scoringType
              }
              rounds{
                pools{
                  id
                }
              }
            }
          }
          `,
        },
      }).then((result) => {
        this.eventData = result.data.data.event;
        this.loading = false;
      });
    },
  },
  beforeMount() {
    this.loadData();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.no-rounds-finihed {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 50px;
  h4 {
    font-size: 20px;
    @include Gilroy-Bold;
    margin: 0 0 8px 0;
  }

  p {
    font-size: 16px;
    text-align: center;
    color: $strom;
    padding: 0 10%;
  }
}

.stat-charts {
  display: flex;
  justify-content: space-between;
  margin-bottom: 34px;
}

.division-results {
  margin-bottom: 44px;
  padding: 0 12px;

  .header {
    background-color: $mist;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    height: 32px;
    align-items: center;
    padding: 0 10px;
    position: sticky;
    top: 0;
    z-index: 2;
    h4 {
      margin: 0;
      font-size: 14px;
      text-align: left;

      span {
        @include Gilroy-Regular;
      }
    }
  }
}
.stat-container {
  width: 33%;
  max-width: 90px;

  p {
    margin-top: 10px;
    margin-bottom: 0px;
    text-align: center;
    font-size: 12px;
    color: $wolf;
    @include Gilroy-Bold;
  }
}
.landscape {
  display: none;
}

table {
  width: 100%;
  table-layout: fixed;

  thead {
    th {
      font-size: 10px;
      @include Gilroy-Bold;
      color: $midnight;
    }
  }

  th {
    position: sticky;
    top: 30px;
    z-index: 1;
    background-color: white;

    &.position {
      width: 15%;
      text-align: center;
    }
    &.player {
      width: auto;
    }
    &.results {
      width: 10%;
    }
    &.par {
      width: 15%;
      text-align: center;
    }
    &.points {
      width: 15%;
      text-align: center;
      &:after {
        content: "pts";
      }
    }
    &.total {
      width: 15%;
      text-align: center;

      &:before {
        content: "Tot";
      }
    }
  }

  tbody {
    td {
      height: 42px;
    }
  }

  td {
    height: 40px;
    font-size: 16px;
    border-bottom: 1px solid $mouse;
    &.dnf {
      text-align: center;
    }
    &.position {
      text-align: center;
      @include Gilroy-Regular;
    }
    &.total {
      text-align: center;
      @include Gilroy-Regular;
    }
    &.result {
      @include Gilroy-Regular;
    }
    &.player {
      @include Gilroy-Bold;
      line-height: 16px;
    }
    &.points {
      text-align: center;
    }
    &.par {
      @include Gilroy-Regular;
      text-align: center;
    }
    &:last-child {
      @include Gilroy-Bold;
    }
  }
}

h4 {
  margin-bottom: 15px;
}

@media (max-width: 813px) and (orientation: landscape) {
  .header {
    position: relative;
  }
  table {
    thead {
      th {
        top: 0;
      }
    }
  }
}
@media (min-width: 480px) and (orientation: landscape) {
  .landscape {
    display: table-cell;
  }

  table {
    th {
      &.position {
        width: 8%;
      }
      &.results {
        width: 8%;
      }
      &.rating {
        width: 10%;
        text-align: center;

        &:before {
          content: "Rat";
        }
      }
      &.par {
        padding-right: 0;
        width: 7%;
      }
      &.total {
        width: 7%;
        padding-right: 0;
      }
      &.results {
        text-align: center;
      }
      &.points {
        width: 7%;
        text-align: center;
        &:after {
          content: "pts";
        }
      }
    }
    td {
      &.rating {
        @include Gilroy-Regular;
        text-align: center;
      }
      &.result {
        text-align: center;
      }
      &.points {
        text-align: center;
      }
      &.dnf {
        text-align: center;
      }
    }
  }
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}
@media (min-width: 992px) {
}
@media (min-width: 1200px) {
  .no-rounds-finihed {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h4 {
      font-size: 24px;
      @include Gilroy-Bold;
      margin: 0 0 24px 0;
    }

    p {
      font-size: 18px;
      text-align: center;
      color: $strom;
      padding: 0 10%;
    }
  }

  .stat-charts {
    margin-bottom: 94px;
    margin-top: 0;
  }
  .stat-container {
    width: 100px;
    max-width: 100px;

    p {
      font-size: 14px;
      letter-spacing: 0.5px;
    }
  }

  .division-results {
    padding: 0;
    margin-bottom: 60px;
    margin-top: 0;

    .header {
      padding: 0 20px;
      height: 40px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      h4 {
        text-align: left;
        font-size: 18px;
      }
    }
  }
  h4 {
    margin: 30px 0px 30px 30px;
  }
  table {
    thead {
      font-size: 12px;
      @include Gilroy-Bold;
      color: $elephant;

      th {
        text-align: center;
        background-color: white;
        top: 40px;
        font-size: 14px;
        &.position {
          width: 8%;
          text-align: center;
        }
        &.player {
          width: auto;
          text-align: left;
        }
        &.rating {
          width: 10%;
          &:before {
            content: "Rating";
          }
        }
        &.pdga {
          width: 10%;
        }
        &.results {
          width: 8%;
        }
        &.par {
          width: 8%;
        }
        &.total {
          width: 8%;
          text-align: center;

          &:before {
            content: "Total";
          }
        }
        &.points {
          &:after {
            content: "Points";
          }
        }
      }
    }

    tbody {
      td {
        text-align: center;
        height: 50px;
        border-bottom: 1px solid $fog;
        background-color: white;
        font-size: 16px;

        a {
          &:hover {
            text-decoration: underline;
          }
        }

        &.position {
          text-align: center;
        }

        &.player {
          width: auto;
          text-align: left;
          @include Gilroy-Bold;
        }
        &.rating {
          width: 10%;
        }
        &.pdga {
          width: 10%;
        }
        &.results {
          width: 5%;
        }
        &.par {
          width: 8%;
          @include Gilroy-Bold;
        }
        &.total {
          text-align: center;
          padding: 0;
          @include Gilroy-Bold;
        }
        &.dnf {
          width: 10%;
          @include Gilroy-Bold;
        }
        &.points {
          text-align: center;
          padding: 0;
        }
      }
    }
  }
}
</style>
